import Dispatcher from '../../../flux/dispatcher/dispatcher';
import Constants from '../constants/logoutConstants';
import LogoutConstant from '../../MainFrame/constants/logoutConstants';
import cookie from '../../../utils/cookie';
// var Dispatcher = require('dispatcher/dispatcher');
var EventEmitter = require('events').EventEmitter;
// var Constants = require('../constants/logoutConstants');
// var LogoutConstant = require("../../MainFrame/constants/logoutConstants");
var assign = require('object-assign');
var RESPONSE_CHANGE_EVENT = 'globalResponse';

function parseResponse(resp) {

}

var LogoutStore = assign({}, EventEmitter.prototype, {
    emitChangeEvent: function (event) {
        this.emit(event);
    },
    bind: function (callback) {
        this.on(RESPONSE_CHANGE_EVENT, callback);
    },
    unbind: function (callback) {
        this.removeListener(RESPONSE_CHANGE_EVENT, callback);
    },
    getResponse: function () {
        return
    }
});

Dispatcher.register(function (action) {
    switch (action.actionType) {
        case Constants.LOGOUT_RESPONSE:
            var resp = action.data;
            // console.log("LogoutStore response", resp)
            parseResponse(resp);
            LogoutStore.emitChangeEvent(RESPONSE_CHANGE_EVENT);
            break;
        case LogoutConstant.UNAUTHORIZED_EXCEPTION:
            // console.trace("check the caller")
            // console.log("logout store")
            cookie.eraseCookie('superuser');
            cookie.eraseCookie('superusername');
            cookie.eraseCookie("superuserid");
            cookie.eraseCookie('superuserimage');
            cookie.eraseCookie("superuserrole");
            cookie.eraseCookie('lctoken');
            LogoutStore.emitChangeEvent(RESPONSE_CHANGE_EVENT);
            break;
        default:
    }
});

export default LogoutStore;